<template>
  <div class="app-mall-confirm container">
    <div class="content ex padding-20-0">
      <el-steps :active="2" process-status="success" simple>
        <el-step title="我的购物车" />
        <el-step title="填写核对订单信息" />
        <el-step title="成功提交订单" />
      </el-steps>
      <div class="fs-huge ta-c fc-p padding-20">
        <i class="fas fa-check"></i>&nbsp;您的订单已经成功提交！
      </div>
      <div class="ta-c lh-150">
        恭喜，您的订单已经成功提交！您可以在商城的“订单管理”中查看提交的订单及其详细，
        <br />也可在后台管理系统中的“采购单管理”中进行查看。
        <br />
        <br />您可以选择继续进行以下操作：
      </div>
      <div class="padding-20 h m">
        <el-button type="primary" @click="handleBack">返回购物车</el-button>
        <el-button type="primary" @click="handleList">继续逛逛</el-button>
        <el-button type="primary" @click="handleOrder">查看订单</el-button>
      </div>
    </div>

    <el-dialog title="支付" :visible.sync="pay.show" width="720px">
      <online-pay ref="online" type="purchase" :money="pay.info.remainMoney" :supplier="order.sellerId" :order-form-id="order.id" @paied="handlePaied" v-if="pay.info" />
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import onlinePay from "@/views/assembly/onlinePay";

export default {
  components: { onlinePay },
  data() {
    return {
      order: null,
      pay: {
        info: null,
        show: false
      }
    };
  },
  methods: {
    loadPayInfo() {
      if (this.order && this.order.id) {
        request({
          url: "api/orderForm/payInfo",
          method: "post",
          data: {
            orderFormId: this.order.id
          }
        }).then(res => {
          res.remainMoney = res.orderTotalMoney - res.useMoney - res.usedRebate;
          if (res.remainMoney < 0) res.remainMoney = 0;
          this.pay.info = res;
          this.pay.show = true;
        });
      }
    },
    handleBack() {
      this.$router.back();
    },
    handleList() {
      this.$router.replace({
        path: "/mall/retail/list"
      });
    },
    handleOrder() {
      if (this.$route.params.id) {
        this.$router.replace({
          path: "/mall/retail/order/" + this.$route.params.id
        });
      }
    },
    handlePaied(checked) {
      this.pay.show = false;
      if (checked === true && this.$route.params.id) {
        this.$router.replace({
          path: "/mall/retail/order/" + this.$route.params.id
        });
      }
    }
  },
  activated() {
    this.order = JSON.parse(
      window.localStorage.getItem("retail-cart-complete-order") || "{}"
    );
    if (this.order && this.order.id && this.order.payType === 0) {
      this.loadPayInfo();
    }
  },
  deactivated() {
    this.order = null;
    this.pay.info = null;
    window.localStorage.removeItem("retail-cart-complete-order");
  }
};
</script>